export const TSWIFT_ALBUM_INFO = [
    {},
    {
        name: "Taylor Swift",
        date: "October 24, 2006",
        image: require(`./assets/album_imgs/1.png`),
        funFacts: [
            "Taylor Swift is her name and the name of the album. Coincidence? I think not, worth sending an investigation team for looking into all the possible conspiracies around this."
        ], 
    },
    {
        name: "Fearless",
        date: "November 11, 2008",
        image: require(`./assets/album_imgs/2.png`),
        funFacts: [
            "Taylor wrote her song \"Love Story\" within 20 minutes after she was scolded by her mom.",
            "Taylor never officially dated the guy \"Tell Me Why\" is about."
        ], 
    },
    {
        name: "Speak Now",
        date: "October 25, 2010",
        image: require(`./assets/album_imgs/3.png`),
        funFacts: [
            "Taylor wrote every single song on this album herself.",
        ], 
    },
    {
        name: "Red",
        date: "October 22, 2012",
        image: require(`./assets/album_imgs/4.png`),
        funFacts: ["This album sold 1.208 million copies during its debut week, almost beating Eminems records of 1.322 million in 2002",
                   "This is the first album Taylor decided to work for different song writers rather than Nathan Chapman, whom she wrote the first three albums with."
        ], 
    },
    {
        name: "1989",
        date: "October 24, 2006",
        image: require(`./assets/album_imgs/5.png`),
        funFacts: [
            "1989 is the year she was born!",
            "50 Cent secretly loved this album!",
            "This is when taylor swift become a sellout and moved from Nashville to New York"
        ], 
    },
    {
        name: "Reputation",
        date: "November 10, 2017",
        image: require(`./assets/album_imgs/6.png`),
        funFacts: [
            "Taylor had her first curse word in this album. Fucking degenerate.",
            "Gorgeous is about Ryan Renods and Blake Lively's eldest daughter James."
        ], 
    },
    {
        name: "Lover",
        date: "August 23, 2019",
        image: require(`./assets/album_imgs/7.png`),
        funFacts: ["Taylor was going to originallyname this album Daylight instead."], 
    },
    {
        name: "Folklore",
        date: "July 24, 2020",
        image: require(`./assets/album_imgs/8.png`),
        funFacts: [
            "Her 1st song in the album is named The 1. Your phone number country code extension starts with 1. Coincidence? I don't think so."
        ], 
    },
    {
        name: "Evermore",
        date: "Decenber 11, 2020",
        image: require(`./assets/album_imgs/9.png`),
        funFacts: [
            "Marjorie is a tribute to her passed grandmom."
        ], 
    },
    {
        name: "Attack of the Awugawa",
        date: "Behind you.",
        image: require(`./assets/album_imgs/10.PNG`),
        funFacts: [
                "Awuguwas once ruled the world before the stone age. They pillaged for human flesh and fed them to their newborn children."
        ], 
    },
];
