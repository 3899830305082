import React, { Component } from "react";
import {LinearGradient} from "expo-linear-gradient";
import {Dimensions, Image, Button, StyleSheet, View, TouchableOpacity, Text } from "react-native";
import {TSWIFT_ALBUM_INFO} from "./tswift_albums"

const getFunFact = (funFacts) => {
    return funFacts[Math.floor(Math.random(funFacts.length))]
}

const DEFAULT_PHRASE = "You should listen to...";
const ALTERNATE_PHRASES = [
    "Grab some ramen and coke and jam out to...",
    "Close your eyes, pretend your vibing on the Gaff dance floor listening to...",
    "Stressful day? No problem, this album should help...",
    "I don't wanna look at anything else now that I saw this album...",
    "I tried to pick my album 'til the album picked me...",
    "Test failed? At least you have alot more time to listen this another album...",
    "Chose listening to taylor swift over a call to mom? Good choice! (Says tswift). Listen to...",
    "I would say the non-existing 10th album, but since I can't...",
    "Maybe listen to another artist or something? Ugh, who am I kidding, I'll do what im programmed to do...",
]

const styles = StyleSheet.create({
    root : {
        minHeight: Dimensions.get('window').height,
        display: 'flex'
    },
    container: {
        minHeight: Dimensions.get('window').height,
        marginLeft: 30,
        marginTop: 30,
        marginRight: 30,
    },
    albumPic: {
        width: 200,
        height: 200,
        marginBottom: 5,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "rgba(114,75,227,.2)",
        borderRadius: 30,
        borderWidth: 8,
        borderColor: "rgba(255,255,255,.8)",
        width: 110,
        height: 70,
        marginBottom: 40,
        marginLeft: -20
    },
    normalText: {
        fontSize: 22,
        color: 'rgba(37, 37, 27, 0.57)', 
        fontWeight: 'bold',
    },
    songText: {
        fontSize: 30,
        fontWeight: 'bold',
        color: "rgba(255,255,255,.8)",
        textAlign: 'center',
    },
    funFactText: {
        fontSize: 18,
        textAlign: 'center',
        marginTop: 10,
        color: 'rgba(37, 37, 27, 0.57)', 
    },
    dateText: {
        fontSize: 18,
        color: 'rgba(37, 37, 27, 0.57)', 
        fontWeight: 'bold'
    },
    buttonText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: "rgba(255,255,255,1)",
    },
    funFactTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'rgba(37, 37, 27, 0.77)', 
    },
    songContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 20,
        marginTop: 10,
    },
    funFact: {
        marginTop: 50,
        alignItems: 'center',
    },
    
});

const backToHome = (navigation) => {
    return navigation.navigate("Home")
}

export const Decision = ({navigation, route}) => {

    const {albumNo} = route.params;
    const albumInfo = TSWIFT_ALBUM_INFO[albumNo];
    const displayFunFact = (albumNo == TSWIFT_ALBUM_INFO.length - 1) ||  Math.random() < .5  && albumInfo.funFacts.length > 0;
    const listenPhrase = Math.random() < .25
        ? ALTERNATE_PHRASES[Math.floor(Math.random()* ALTERNATE_PHRASES.length)]
        : DEFAULT_PHRASE

    return (
        <LinearGradient
            style = {styles.root}
            colors = {['#cc2b5e', '#753a88']}
        >
            <View style = {styles.container}>
                <View>
                    <Text style = {styles.normalText}>
                        {listenPhrase}
                    </Text>
                </View>
                <View style = {styles.songContainer}>
                    <Image
                        style = {styles.albumPic}
                        source = {albumInfo.image}
                    />
                    <Text style = {styles.songText}>
                        {albumInfo.name} 
                    </Text>
                    <Text style = {styles.dateText}>
                        Date: {albumInfo.date}
                    </Text>
                {displayFunFact &&
                    <View style = {styles.funFact}>
                        <Text style = {styles.funFactTitle}>
                            You deserve an album fun fact! {"\n"} Did you know:   
                        </Text>
                        <Text style = {styles.funFactText}>
                            {getFunFact(albumInfo.funFacts)} 
                        </Text>
                    </View>
                }
                </View>
                <TouchableOpacity
                    style = {styles.button}
                    onPress = {() => backToHome(navigation)}
                >
                    <Text style = {styles.buttonText}>
                        Back
                    </Text>
                </TouchableOpacity>
            </View>
        </LinearGradient>
    )
}

export default Decision; 
