import 'react-native-gesture-handler';
import React, { Component } from "react";
import {Button, StyleSheet, View, TouchableOpacity, Text } from "react-native";
import {NavigationContainer} from '@react-navigation/native'
import {createStackNavigator} from '@react-navigation/stack'
import {Home} from './Home'
import {Decision} from './Decision' 

const Stack = createStackNavigator()   

const App = () => {
    return (
        <NavigationContainer>
            <Stack.Navigator
                screenOptions = {{
                    headerShown: false,
                }}
            >
                <Stack.Screen
                    name = "Home"
                    component = {Home}
                    options = {{title: "Swiftimizer"}}
                />
                <Stack.Screen
                    name = "Decision"
                    component = {Decision}
                    options = {{
                        title: "Taylor Swift's executive decision",
                    }}
                />
            </Stack.Navigator>

        </NavigationContainer>
    )
}
export default App;
