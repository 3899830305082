import React, { Component } from "react";
import {Dimensions, Button, StyleSheet, View, TouchableOpacity, Text, Image } from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {TSWIFT_ALBUM_INFO} from "./tswift_albums"


const styles = StyleSheet.create({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: Dimensions.get('window').height,
    },
    upperhalf: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    lowerhalf: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
       width: 220,
       height: 220,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "rgba(114,75,227,.2)",
        borderRadius: 30,
        borderWidth: 8,
        borderColor: "rgba(255,255,255,.8)",
        width: 271,
        height: 100,
    },
    swiftimize: {
        color: "rgba(255,255,255,1)",
        fontSize: 40,
        fontWeight: 'bold',
    }    
});


const onSwiftimize = (navigation) => {
    const albumNo = Math.floor(Math.random() * (TSWIFT_ALBUM_INFO.length - 1)) + 1;
    navigation.navigate('Decision', {albumNo: albumNo});    
}

export const Home = ({navigation}) => {
    return (
        <LinearGradient
            style = {styles.root}
            colors = {['#cc2b5e', '#753a88']}
        >
            <View style = {styles.upperhalf}>
                <Image 
                    style = {styles.logo}
                    source = {require('./assets/logo.png')}
                />
            </View>
            <View style = {styles.lowerhalf}> 
                <View style = {styles.button}>
                    <TouchableOpacity
                       onPress = {() => onSwiftimize(navigation)} 
                    >
                        <Text 
                            style = {styles.swiftimize}
                        >
                            Swiftimize
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </LinearGradient>
    )
}

export default Home;
